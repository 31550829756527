import Country from "@/api/objects/Country";

const storeFrontsMap = {
    "143610" : {code: "af", name: "Afghanistan"},
    "143575" : {code: "al", name: "Albania"},
    "143563" : {code: "dz", name: "Algeria"},
    "143564" : {code: "ao", name: "Angola"},
    "143538" : {code: "ai", name: "Anguilla"},
    "143540" : {code: "ag", name: "Antigua and Barbuda"},
    "143505" : {code: "ar", name: "Argentina"},
    "143524" : {code: "am", name: "Armenia"},
    "143460" : {code: "au", name: "Australia"},
    "143445" : {code: "at", name: "Austria"},
    "143568" : {code: "az", name: "Azerbaijan"},
    "143539" : {code: "bs", name: "Bahamas"},
    "143559" : {code: "bh", name: "Bahrain"},
    "143541" : {code: "bb", name: "Barbados"},
    "143565" : {code: "by", name: "Belarus"},
    "143446" : {code: "be", name: "Belgium"},
    "143555" : {code: "bz", name: "Belize"},
    "143576" : {code: "bj", name: "Benin"},
    "143542" : {code: "bm", name: "Bermuda"},
    "143577" : {code: "bt", name: "Bhutan"},
    "143556" : {code: "bo", name: "Bolivia"},
    "143612" : {code: "ba", name: "Bosnia and Herzegovina"},
    "143525" : {code: "bw", name: "Botswana"},
    "143503" : {code: "br", name: "Brazil"},
    "143543" : {code: "vg", name: "British Virgin Islands"},
    "143560" : {code: "bn", name: "Brunei"},
    "143526" : {code: "bg", name: "Bulgaria"},
    "143578" : {code: "bf", name: "Burkina Faso"},
    "143579" : {code: "kh", name: "Cambodia"},
    "143574" : {code: "cm", name: "Cameroon"},
    "143455" : {code: "ca", name: "Canada"},
    "143580" : {code: "cv", name: "Cape Verde"},
    "143615" : {code: "ge", name: "Georgia"},
    "143544" : {code: "ky", name: "Cayman Islands"},
    "143581" : {code: "td", name: "Chad"},
    "143483" : {code: "cl", name: "Chile"},
    "143465" : {code: "cn", name: "China"},
    "143501" : {code: "co", name: "Colombia"},
    "143495" : {code: "cr", name: "Costa Rica"},
    "143494" : {code: "hr", name: "Croatia"},
    "143557" : {code: "cy", name: "Cyprus"},
    "143489" : {code: "cz", name: "Czech Republic"},
    "143613" : {code: "cd", name: "Democratic Republic of the Congo"},
    "143458" : {code: "dk", name: "Denmark"},
    "143545" : {code: "dm", name: "Dominica"},
    "143508" : {code: "do", name: "Dominican Republic"},
    "143509" : {code: "ec", name: "Ecuador"},
    "143516" : {code: "eg", name: "Egypt"},
    "143506" : {code: "sv", name: "El Salvador"},
    "143518" : {code: "ee", name: "Estonia"},
    "143602" : {code: "sz", name: "Eswatini"},
    "143591" : {code: "fm", name: "Federated States of Micronesia"},
    "143583" : {code: "fj", name: "Fiji"},
    "143447" : {code: "fi", name: "Finland"},
    "143442" : {code: "fr", name: "France"},
    "143614" : {code: "ga", name: "Gabon"},
    "143584" : {code: "gm", name: "Gambia"},
    "143443" : {code: "de", name: "Germany"},
    "143573" : {code: "gh", name: "Ghana"},
    "143448" : {code: "gr", name: "Greece"},
    "143546" : {code: "gd", name: "Grenada"},
    "143504" : {code: "gt", name: "Guatemala"},
    "143585" : {code: "gw", name: "Guinea-Bissau"},
    "143553" : {code: "gy", name: "Guyana"},
    "143510" : {code: "hn", name: "Honduras"},
    "143463" : {code: "hk", name: "Hong Kong"},
    "143482" : {code: "hu", name: "Hungary"},
    "143558" : {code: "is", name: "Iceland"},
    "143467" : {code: "in", name: "India"},
    "143476" : {code: "id", name: "Indonesia"},
    "143617" : {code: "iq", name: "Iraq"},
    "143449" : {code: "ie", name: "Ireland"},
    "143491" : {code: "il", name: "Israel"},
    "143450" : {code: "it", name: "Italy"},
    "143527" : {code: "ci", name: "Ivory Coast"},
    "143511" : {code: "jm", name: "Jamaica"},
    "143462" : {code: "jp", name: "Japan"},
    "143528" : {code: "jo", name: "Jordan"},
    "143517" : {code: "kz", name: "Kazakhstan"},
    "143529" : {code: "ke", name: "Kenya"},
    "143624" : {code: "xk", name: "Kosovo"},
    "143493" : {code: "kw", name: "Kuwait"},
    "143586" : {code: "kg", name: "Kyrgyzstan"},
    "143587" : {code: "la", name: "Laos"},
    "143519" : {code: "lv", name: "Latvia"},
    "143497" : {code: "lb", name: "Lebanon"},
    "143588" : {code: "lr", name: "Liberia"},
    "143567" : {code: "ly", name: "Libya"},
    "143520" : {code: "lt", name: "Lithuania"},
    "143451" : {code: "lu", name: "Luxembourg"},
    "143515" : {code: "mo", name: "Macao"},
    "143531" : {code: "mg", name: "Madagascar"},
    "143589" : {code: "mw", name: "Malawi"},
    "143473" : {code: "my", name: "Malaysia"},
    "143488" : {code: "mv", name: "Maldives"},
    "143532" : {code: "ml", name: "Mali"},
    "143521" : {code: "mt", name: "Malta"},
    "143590" : {code: "mr", name: "Mauritania"},
    "143533" : {code: "mu", name: "Mauritius"},
    "143468" : {code: "mx", name: "Mexico"},
    "143523" : {code: "md", name: "Moldova"},
    "143592" : {code: "mn", name: "Mongolia"},
    "143619" : {code: "me", name: "Montenegro"},
    "143547" : {code: "ms", name: "Montserrat"},
    "143620" : {code: "ma", name: "Morocco"},
    "143593" : {code: "mz", name: "Mozambique"},
    "143570" : {code: "mm", name: "Myanmar"},
    "143594" : {code: "na", name: "Namibia"},
    "143606" : {code: "nr", name: "Nauru"},
    "143484" : {code: "np", name: "Nepal"},
    "143452" : {code: "nl", name: "Netherlands"},
    "143461" : {code: "nz", name: "New Zealand"},
    "143512" : {code: "ni", name: "Nicaragua"},
    "143534" : {code: "ne", name: "Niger"},
    "143561" : {code: "ng", name: "Nigeria"},
    "143530" : {code: "mk", name: "North Macedonia"},
    "143457" : {code: "no", name: "Norway"},
    "143562" : {code: "om", name: "Oman"},
    "143477" : {code: "pk", name: "Pakistan"},
    "143595" : {code: "pw", name: "Palau"},
    "143485" : {code: "pa", name: "Panama"},
    "143597" : {code: "pg", name: "Papua New Guinea"},
    "143513" : {code: "py", name: "Paraguay"},
    "143507" : {code: "pe", name: "Peru"},
    "143474" : {code: "ph", name: "Philippines"},
    "143478" : {code: "pl", name: "Poland"},
    "143453" : {code: "pt", name: "Portugal"},
    "143498" : {code: "qa", name: "Qatar"},
    "143582" : {code: "cg", name: "Republic of the Congo"},
    "143487" : {code: "ro", name: "Romania"},
    "143469" : {code: "ru", name: "Russia"},
    "143621" : {code: "rw", name: "Rwanda"},
    "143548" : {code: "kn", name: "Saint Kitts and Nevis"},
    "143549" : {code: "lc", name: "Saint Lucia"},
    "143550" : {code: "vc", name: "Saint Vincent and the Grenadines"},
    "143598" : {code: "st", name: "Sao Tome and Principe"},
    "143479" : {code: "sa", name: "Saudi Arabia"},
    "143535" : {code: "sn", name: "Senegal"},
    "143500" : {code: "rs", name: "Serbia"},
    "143599" : {code: "sc", name: "Seychelles"},
    "143600" : {code: "sl", name: "Sierra Leone"},
    "143464" : {code: "sg", name: "Singapore"},
    "143496" : {code: "sk", name: "Slovakia"},
    "143499" : {code: "si", name: "Slovenia"},
    "143601" : {code: "sb", name: "Solomon Islands"},
    "143472" : {code: "za", name: "South Africa"},
    "143466" : {code: "kr", name: "South Korea"},
    "143454" : {code: "es", name: "Spain"},
    "143486" : {code: "lk", name: "Sri Lanka"},
    "143554" : {code: "sr", name: "Suriname"},
    "143456" : {code: "se", name: "Sweden"},
    "143459" : {code: "ch", name: "Switzerland"},
    "143470" : {code: "tw", name: "Taiwan"},
    "143603" : {code: "tj", name: "Tajikistan"},
    "143572" : {code: "tz", name: "Tanzania"},
    "143475" : {code: "th", name: "Thailand"},
    "143608" : {code: "to", name: "Tonga"},
    "143551" : {code: "tt", name: "Trinidad and Tobago"},
    "143536" : {code: "tn", name: "Tunisia"},
    "143480" : {code: "tr", name: "Turkey"},
    "143604" : {code: "tm", name: "Turkmenistan"},
    "143552" : {code: "tc", name: "Turks and Caicos"},
    "143537" : {code: "ug", name: "Uganda"},
    "143492" : {code: "ua", name: "Ukraine"},
    "143481" : {code: "ae", name: "United Arab Emirates"},
    "143444" : {code: "gb", name: "United Kingdom"},
    "143441" : {code: "us", name: "United States"},
    "143514" : {code: "uy", name: "Uruguay"},
    "143566" : {code: "uz", name: "Uzbekistan"},
    "143609" : {code: "vu", name: "Vanuatu"},
    "143502" : {code: "ve", name: "Venezuela"},
    "143471" : {code: "vn", name: "Vietnam"},
    "143571" : {code: "ye", name: "Yemen"},
    "143622" : {code: "zm", name: "Zambia"},
    "143605" : {code: "zw", name: "Zimbabwe"},
};

export default function getCountryByStoreFrontId(storeFrontId, name = null) {
    if (!storeFrontsMap[storeFrontId]) {
        return new Country(name || "Other", "other");
    }

    return new Country(name || storeFrontsMap[storeFrontId].name, storeFrontsMap[storeFrontId].code);
}