<template>
  <div>
    <AppAnalytics :app="currentApp" :key="currentApp.id"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CategoryRanking from "@/components/AppDetailsComponent/components/CategoryRanking/index.vue";
import AppAnalytics from "@/components/AppDetailsComponent/components/Analytics/AppAnalytics.vue";

export default {
  name: "DashboardAppAnalytics",
  components: {
    AppAnalytics,
    CategoryRanking,
  },
  computed: {
    ...mapGetters([
      'currentApp',
    ]),
  },
}
</script>
