<script setup>
import {computed, nextTick, ref, watch} from "vue";
import DatepickerWithRangeOld from "@/components/UI/DatepickerWithRangeOld/index.vue";
import {colorByIndex, formatDate, getNDaysAgoDate, percentDiff, shortUnits} from "@/utils/utils";
import InfoTip from "@/components/InfoTip/InfoTip.vue";
import Dynamic from "@/components/UI/Dynamic/Dynamic.vue";
import DynamicImage from "@/components/UI/DynamicImage/index.vue";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import CustomSelectNew from "@/components/Forms/CustomSelectNew/index.vue";
import IconGraphTypeLines from "@/components/icons/IconGraphTypeLines.vue";
import IconGraphTypeColumns from "@/components/icons/IconGraphTypeColumns.vue";
import AppStoreConnect from "@/api/modules/app-store-connect";
import getCountryByStoreFrontId from "@/utils/appleStoreFronts";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import metadataJournal from "@/api/modules/metadata-journal";
import makeMockForMeasure from "@/utils/appleTimeSeriesMock";

const props = defineProps({
  app: {
    type: Object,
    required: true,
  }
})

const dateFilter = ref({
  start: getNDaysAgoDate(31),
  end: getNDaysAgoDate(1),
})
const minDate = getNDaysAgoDate(365); //TODO а какой минимум по факту?
const maxDate = getNDaysAgoDate(1);

const mode = ref('sources')

const isShowTableChanges = ref(true)

const handleCheckAll = (isChecked, changedItems) => {
  if (!changedItems) {
    changedItems = tableItems.value.filter((i) => {
      return i.isChecked === isChecked
    })
  }
  changedItems.forEach((item) => {
    handleCheckRow(item)
  })
}

const visibleGroupsStorage = ref({})
const handleCheckRow = (item) => {
  if (item.isChecked) {
    visibleGroupsStorage.value[item.group] = item
  } else {
    delete visibleGroupsStorage.value[item.group]
  }
}

const percentFormatter = (value) => {
  return value.toFixed(2) + '%';
}

const applyDatePicker = (nDateFilter) => {
  dateFilter.value = {
    start: new Date(nDateFilter.start),
    end: new Date(nDateFilter.end),
  };
}

const tableRef = ref(null);

const itemGroups = ref({})

const measures = [
  'proceeds',
  'sales',
  'totalDownloads',
  'redownloads',
  'units',
  'pageViewCount',
  'impressionsTotal',
  'impressionsTotalUnique',
]
const selectedChartStep = ref('Day')
const hasConnection = ref(false)

const isLoaded = ref(false)
const loadData = () => {
  isLoaded.value = false

  const datesDiffInDays = Math.floor((dateFilter.value.end - dateFilter.value.start) / (1000 * 60 * 60 * 24));
  const dimension = mode.value === 'countries' ? 'storefront' : 'source';

  itemGroups.value = {}

  AppStoreConnect.userAppConnections(props.app.id).then((r) => {
    hasConnection.value = r.store_connection.has_email_share_connection
    const promises = hasConnection.value ? [
      AppStoreConnect.timeSeries(
          props.app.id,
          formatDate(getNDaysAgoDate(datesDiffInDays, dateFilter.value.start), 'year-month-realDay'),
          formatDate(getNDaysAgoDate(1, dateFilter.value.start), 'year-month-realDay'),
          selectedChartStep.value.toLowerCase(),
          measures,
          dimension,
      ),
      AppStoreConnect.timeSeries(
          props.app.id,
          formatDate(dateFilter.value.start, 'year-month-realDay'),
          formatDate(dateFilter.value.end, 'year-month-realDay'),
          selectedChartStep.value.toLowerCase(),
          measures,
          dimension,
      )
    ] : [
      new Promise((resolve) => resolve(
          measures.map((m) => {
            return makeMockForMeasure(
                m,
                getNDaysAgoDate(datesDiffInDays, dateFilter.value.start),
                getNDaysAgoDate(1, dateFilter.value.start),
            )
          })
      )),
      new Promise((resolve) => resolve(
          measures.map((m) => {
            return makeMockForMeasure(
                m,
                dateFilter.value.start,
                dateFilter.value.end,
            )
          })
      )),
    ]

    Promise.all(promises).then((responses) => {
      responses[1].forEach((r, rI) => {
        r.forEach((item, iI) => {
          if (!itemGroups.value[item.group.key]) {
            itemGroups.value[item.group.key] = {
              title: item.group.title,
              color: colorByIndex(iI),
              totals: {},
              timeline: {},
            }
          }

          itemGroups.value[item.group.key].totals[item.totals.key] = {
            value: item.totals.value,
            prevValue: responses[0]?.[rI]?.[iI]?.totals.value,
          };

          item.data.forEach((d) => {
            if (!itemGroups.value[item.group.key].timeline[d.date]) {
              itemGroups.value[item.group.key].timeline[d.date] = {}
            }
            itemGroups.value[item.group.key].timeline[d.date][item.totals.key] = d[item.totals.key];
          })
        });
      })
    }).then(() => {
      nextTick(() => {
        tableRef.value.handleAllItemsCheck(true)
        isLoaded.value = true
      })
    }).catch((e) => {
      console.error(e)
      alert('Something went wrong')
    })
  })
}
loadData()

watch([
  mode,
  selectedChartStep,
  dateFilter
], loadData)

const getCvr = (a, b) => {
  return b ? Number((a / b * 100).toFixed(2)) : null;
}

const makeTipChartOptions = function (dates, data, type = 'column') {
  return {
    chart: {
      type: type,
      height: 30,
      margin: 0,
    },
    title: false,
    legend: {
      enabled: false
    },
    xAxis: {
      title: false,
      type: 'datetime',
      offset: 3,
      categories: dates,
      labels: {
        enabled: false
      },
      gridLineColor: '#ffffff',
      visible: false
    },
    yAxis: {
      title: false,
      labels: {
        enabled: false
      },
      gridLineColor: '#ffffff',
    },
    plotOptions: {
      series: {
        animation: false,
        color: 'var(--primary-default)',
        opacity: 1,
        marker: {
          enabled: false
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            halo: {
              size: 4,
              attributes: {
                fill: 'transparent',
                'stroke-width': 2,
                stroke: 'transparent'
              }
            }
          }
        }
      },
      column: {
        borderRadius: 3,
      },
      areaspline: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, 'rgba(158, 163, 254, 0.75)'],
            [1, 'rgba(158, 163, 254, 0.05)']
          ]
        },
        marker: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    series: [{
      data: data,
      lineWidth: 1,
      marker: {
        states: {
          hover: {
            fillColor: 'white',
            radius: 3,
            lineWidth: 1,
            lineColor: 'var(--primary-default)',
          }
        }
      }
    }],
    tooltip: {
      enabled: false
    }
  }
}

const tips = computed(() => {
  let impressions = 0;
  let pageViews = 0;
  let totalDownloads = 0;
  let revenue = 0;

  let impressionsPrev = 0;
  let pageViewsPrev = 0;
  let totalDownloadsPrev = 0;
  let revenuePrev = 0;

  const timelinesByMeasure = {}

  Object.keys(itemGroups.value).forEach((k) => {
    const g = itemGroups.value[k];

    impressions += g.totals.impressionsTotal.value;
    pageViews += g.totals.pageViewCount.value;
    totalDownloads += g.totals.totalDownloads.value;
    revenue += g.totals.proceeds.value;

    impressionsPrev += g.totals.impressionsTotal.prevValue;
    pageViewsPrev += g.totals.pageViewCount.prevValue;
    totalDownloadsPrev += g.totals.totalDownloads.prevValue;
    revenuePrev += g.totals.proceeds.prevValue;

    Object.keys(g.timeline).forEach((date) => {
      Object.keys(g.timeline[date]).forEach((measure) => {
        if (!timelinesByMeasure[measure]) {
          timelinesByMeasure[measure] = {}
        }
        if (!timelinesByMeasure[measure][date]) {
          timelinesByMeasure[measure][date] = g.timeline[date][measure]
        } else {
          timelinesByMeasure[measure][date] += g.timeline[date][measure]
        }
      })
    })
  })

  Object.keys(timelinesByMeasure['totalDownloads'] ?? []).forEach((date) => {
    if (!timelinesByMeasure['cvr']) {
      timelinesByMeasure['cvr'] = {}
    }

    timelinesByMeasure['cvr'][date] = getCvr(
        timelinesByMeasure['totalDownloads'][date],
        timelinesByMeasure['impressionsTotal'][date]
    )
  })

  return [
    {
      title: 'Impressions',
      value: shortUnits(impressions),
      dynamic: percentDiff(impressionsPrev, impressions),
      chartOptions: makeTipChartOptions(
          dates.value,
          Object.values(timelinesByMeasure['impressionsTotal'] ?? []),
          'areaspline'
      ),
      onClick: () => {
        selectedParameters.value = [parameters[0]]
      }
    },
    {
      title: 'App Page Views',
      value: shortUnits(pageViews),
      dynamic: percentDiff(pageViewsPrev, pageViews),
      chartOptions: makeTipChartOptions(
          dates.value,
          Object.values(timelinesByMeasure['pageViewCount'] ?? []),
          'areaspline'
      ),
    },
    {
      title: 'Total Downloads',
      value: shortUnits(totalDownloads),
      dynamic: percentDiff(totalDownloadsPrev, totalDownloads),
      chartOptions: makeTipChartOptions(
          dates.value,
          Object.values(timelinesByMeasure['totalDownloads'] ?? []),
          'areaspline'
      ),
      onClick: () => {
        selectedParameters.value = [parameters[4]]
      }
    },
    {
      title: 'Revenue',
      value: shortUnits(revenue),
      dynamic: percentDiff(revenuePrev, revenue),
      chartOptions: makeTipChartOptions(
          dates.value,
          Object.values(timelinesByMeasure['proceeds'] ?? []),
          'areaspline'
      ),
      onClick: () => {
        selectedParameters.value = [parameters[6]]
      }
    },
    {
      title: 'CVR to Downloads',
      value: getCvr(totalDownloads, impressions) + '%',
      dynamic: percentDiff(
          getCvr(totalDownloadsPrev, impressionsPrev),
          getCvr(totalDownloads, impressions),
      ),
      chartOptions: makeTipChartOptions(
          dates.value,
          Object.values(timelinesByMeasure['cvr'] ?? []),
          'areaspline'
      )
    }
  ]
})

const parameters = [
  {label: 'Impressions', measure: 'impressionsTotal'},
  {label: 'Impressions (Unique Dev.)', measure: 'impressionsTotalUnique'},
  {label: 'First-time downloads', measure: 'units'},
  {label: 'Redownloads', measure: 'redownloads'},
  {label: 'Total downloads', measure: 'totalDownloads'},
  {label: 'Sales', measure: 'sales'},
  {label: 'Revenue', measure: 'proceeds'},
]

const showingModes = {
  lines: {
    icon: IconGraphTypeLines,
  },
  columns: {
    icon: IconGraphTypeColumns,
  }
}

const selectedParameters = ref([
  parameters[0],
]);
const selectedShowingModes = ref([
  'lines',
  'lines',
])

const tableColumns = computed(() => {
  return {
    ...(mode.value === 'countries' ? {
      'country': {
        header: 'Countries',
        orientation: 'left',
        sortBy: 'country',
      }
    } : {
      'source': {
        header: 'Sources',
        orientation: 'left',
      }
    }),
    'impressions': {
      header: 'Impressions',
      orientation: 'right',
      style: {verticalAlign: 'top'},
      sortBy: 'impressions.value',
      tooltip: 'The number of times the app\'s icon was viewed on the App Store'
    },
    'impressions_cvr': {
      header: 'CVR %',
      orientation: 'right',
      style: {verticalAlign: 'top'},
      sortBy: 'impressions_cvr.value',
      tooltip: 'Conversions (CVR) from Impressions to Page Views. Calculated by dividing page views by impressions.'
    },
    'views': {
      header: 'Page Views',
      orientation: 'right',
      sortBy: 'views.value',
      tooltip: 'The number of times the app\'s product page was viewed on the App Store',
    },
    'views_cvr': {
      header: 'CVR %',
      orientation: 'right',
      style: {verticalAlign: 'top'},
      sortBy: 'views_cvr.value',
      tooltip: 'Conversions (CVR) from Page Views to Installs. Calculated by dividing installs by page views.',
    },
    'installs': {
      header: 'Installs',
      orientation: 'right',
      style: {verticalAlign: 'top'},
      sortBy: 'installs.value',
      tooltip: 'The number of app first-time downloads and redownloads.',
    },
    'installs_cvr': {
      header: 'CVR %',
      orientation: 'right',
      style: {verticalAlign: 'top'},
      sortBy: 'installs_cvr.value',
      tooltip: 'Conversion (CVR) from installs to sales. Calculated by dividing sales by installs.',
    },
    'sales': {
      header: 'Sales',
      orientation: 'right',
      style: {verticalAlign: 'top'},
      sortBy: 'sales.value',
      tooltip: 'The total amount billed to customers for purchasing apps, bundles, and in-app purchases.',
    },
    'revenue': {
      header: 'Revenue',
      orientation: 'right',
      style: {verticalAlign: 'top'},
      sortBy: 'revenue.value',
      tooltip: '(Proceeds) The estimated amount of revenue the developer will receive from their sales, minus Apple’s commission. May not match final payout due to final exchange rates and transaction lifecycle.',
    },
  }
})

//TODO process nulls
const tableItems = computed(() => {
  return Object.keys(itemGroups.value).map((k) => {
    const g = itemGroups.value[k];
    return {
      group: k,
      isChecked: false,

      //TODO
      country: mode.value === 'countries' ? getCountryByStoreFrontId(k, g.title) : null,
      source: g.title,

      impressions: {
        value: g.totals.impressionsTotal.value,
        dynamic: percentDiff(g.totals.impressionsTotal.prevValue, g.totals.impressionsTotal.value)
      },
      impressions_cvr: {
        value: getCvr(g.totals.pageViewCount.value, g.totals.impressionsTotal.value),
        dynamic: percentDiff(
            getCvr(g.totals.pageViewCount.prevValue, g.totals.impressionsTotal.prevValue),
            getCvr(g.totals.pageViewCount.value, g.totals.impressionsTotal.value),
        )
      },
      views: {
        value: g.totals.pageViewCount.value,
        dynamic: percentDiff(g.totals.pageViewCount.prevValue, g.totals.pageViewCount.value)
      },
      views_cvr: {
        value: getCvr(g.totals.totalDownloads.value, g.totals.pageViewCount.value),
        dynamic: percentDiff(
            getCvr(g.totals.totalDownloads.prevValue, g.totals.pageViewCount.prevValue),
            getCvr(g.totals.totalDownloads.value, g.totals.pageViewCount.value),
        )
      },
      installs: {
        value: g.totals.totalDownloads.value,
        dynamic: percentDiff(g.totals.totalDownloads.prevValue, g.totals.totalDownloads.value)
      },
      installs_cvr: {
        value: getCvr(g.totals.sales.prevValue, g.totals.totalDownloads.value),
        dynamic: percentDiff(
            getCvr(g.totals.sales.prevValue, g.totals.totalDownloads.prevValue),
            getCvr(g.totals.sales.value, g.totals.totalDownloads.value),
        )
      },
      sales: {
        value: g.totals.sales.value,
        dynamic: percentDiff(g.totals.sales.prevValue, g.totals.sales.value)
      },
      revenue: {
        value: g.totals.proceeds.value,
        dynamic: percentDiff(g.totals.proceeds.prevValue, g.totals.proceeds.value)
      },
    }
  });
})

const dates = computed(() => {
  const firstGroup = Object.keys(itemGroups.value)[0];
  if (!firstGroup) {
    return [];
  }

  return Object.keys(itemGroups.value[firstGroup].timeline).map((date) => new Date(date).getTime());
})

const itemsToSeries = function (parameter, options = {}) {
  return Object.keys(itemGroups.value)?.filter((k) => visibleGroupsStorage.value[k]).map((k, i) => {
    return {
      name: parameter.label + '|' + itemGroups.value[k].title,
      data: Object.values(itemGroups.value[k].timeline).map((d) => d[parameter.measure]),
      visible: true,
      lineWidth: 2,
      color: itemGroups.value[k].color,
      ...options
    }
  }) ?? []
}
const series = computed(() => {
  const a = itemsToSeries(selectedParameters.value[0], {
    stack: 'A',
    type: selectedShowingModes.value[0] === 'columns' ? 'column' : 'spline'
  })

  let b = []
  if (selectedParameters.value[1]) {
    b = itemsToSeries(selectedParameters.value[1], {
      stack: 'B',
      type: selectedShowingModes.value[1] === 'columns' ? 'column' : 'spline',
      dashStyle: 'Dash',
      borderWidth: 1,
      borderColor: '#5D66FE'
    })
  }

  return a.concat(b)
})
const releases = ref([])
metadataJournal.getReleases(props.app.id).then((r) => {
  releases.value = r
})
const plotLines = computed(() => {
  return releases.value.map((r) => {
    const dateIndex = dates.value.findIndex((d) => d === new Date(formatDate(r.created_at, 'year-month-realDay') + 'T00:00:00Z').getTime())
    if (dateIndex === -1) {
      return null
    }

    return {
      color: '#A3B0C5',
      dashStyle: 'Dash',
      value: dateIndex,
      width: 2,
      zIndex: 2,
      label: {
        text: 'Metadata Update',
        rotation: 90,
        y: 10,
        x: 10,
        style: {
          color: '#838E9E',
        }
      }
    };
  }).filter((r) => r)
})

const chartOptions = ref({})
const setChartOptions = () => {
  chartOptions.value = {
    chart: {
      type: 'spline',
      height: 395,
    },
    title: false,
    legend: {
      enabled: false
    },
    xAxis: {
      reversed: false,
      categories: dates,
      title: false,
      type: 'datetime',
      labels: {
        style: {color: "#505660", fontWeight: "400", fontSize: "14px"},
        rotation: -90,
        format: '{value:%b %d}',
      },
      gridLineDashStyle: "solid",
      gridLineColor: "#e5e8eb",
      gridLineWidth: 0,
      showLine: true,
      lineWidth: 1,
      lineColor: "#e5e8eb",
      offset: 12,
      plotLines: plotLines.value
    },
    yAxis: {
      title: false,
      labels: {
        style: {color: "#505660", fontWeight: "400", fontSize: "14px"},
      },
      gridLineDashStyle: "solid",
      gridLineColor: "#e5e8eb",
      gridLineWidth: 1,
      showLine: true,
      lineWidth: 1,
      lineColor: "#e5e8eb"
    },
    plotOptions: {
      series: {
        color: 'var(--primary-default)',
        opacity: 1,
        animation: false,
        marker: {
          enabled: false
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            halo: {
              size: 4,
              attributes: {
                fill: 'transparent',
                'stroke-width': 2,
                stroke: 'transparent'
              }
            }
          }
        }
      },
      column: {
        stacking: 'normal',
        // colorByPoint: true,
        states: {
          inactive: {
            enabled: false
          },
          hover: {
            enabled: false
          }
        }
      },
    },
    series: series,
    tooltip: {
      useHTML: true,
      shadow: false,
      borderColor: '#E1E8F2',
      shared: true,
      padding: 0,
      borderWidth: 0,
      backgroundColor: 'transparent',
      formatter() {
        let html = `<div class="tooltip-inner-container app-analytics-tooltip">`;
        const pointDate = formatDate(this?.x);
        html += `<table>`;

        const matrix = {};

        const parametersIndex = {};
        this.points.forEach((point) => {
          const explodedName = point.series.name.split('|');
          const parameter = explodedName[0];
          const source = explodedName[1];

          if (!matrix[source]) {
            matrix[source] = {
              color: point.color,
              values: {}
            };
          }
          if (!matrix[source].values[parameter]) {
            matrix[source].values[parameter] = point.y;
          }

          if (!parametersIndex[parameter]) {
            parametersIndex[parameter] = 0;
          }
          parametersIndex[parameter] += point.y;
        });

        html += `<tr>`;
        html += `<td><b>${pointDate}</b></td>`;
        Object.keys(parametersIndex).forEach((parameter) => {
          html += `<td>${parameter} (${parametersIndex[parameter]})</td>`;
        });
        html += `</tr>`;

        const firstParameter = Object.keys(parametersIndex)[0];
        let matrixSortedKeys = Object.keys(matrix).sort((a, b) => {
          return matrix[b].values[firstParameter] - matrix[a].values[firstParameter];
        });

        matrixSortedKeys.forEach((source) => {
          html += `<tr>`;
          html += `<td><div class="d-flex f-align-center f-gap-8"><div class="circle" style="background-color: ${matrix[source].color};"></div> <span>${source}</span></div></td>`;
          Object.keys(parametersIndex).forEach((parameter) => {
            html += `<td>${matrix[source].values[parameter]}</td>`;
          });
          html += `</tr>`;
        });

        html += `</table>`;
        html += `</div>`;

        return html;
      },
    },
  }
}
setChartOptions()

const chartKey = ref(0);
watch(() => [series.value, plotLines.value], () => {
  setChartOptions()
  chartKey.value++;
})
</script>

<template>
<div v-if="!isLoaded" class="content d-flex f-j-center f-align-center" style="min-height: 200px">
  <PreloaderTableSmall text="Connect to account..." loader-size="160px"/>
</div>
<div class="app-analytics" v-show="isLoaded">
  <div class="metrics-section">
    <div class="d-flex f-j-between f-align-center">
      <h3>App Store Analytics</h3>

      <DatepickerWithRangeOld
          v-if="dateFilter"
          :to-right="true"
          :date-filter="dateFilter"
          :last-days-count="30"
          :min-date="minDate"
          :max-date="maxDate"
          :columns="2"
          :ranges="[7, 30, 90, 180]"
          @date-filter-changed="applyDatePicker"/>
    </div>

    <div class="d-flex f-gap-14 f-j-between">
      <InfoTip v-for="(tip, index) in tips" :key="index">
        <template v-slot:tipContent>
          <div class="d-flex f-gap-5">
            <span>{{tip.value}}</span>
            <div class="pt-5">
              <Dynamic :dynamic="tip.dynamic" :dynamic-formatter="percentFormatter"></Dynamic>
            </div>
          </div>
        </template>
        <template v-slot:tipTitle>
          <span class="color-800" :class="{'hover-color-primary': tip.onClick, 'cursor-pointer': tip.onClick, }" @click="tip.onClick ? tip.onClick() : void(0)">{{tip.title}}</span>
        </template>
        <template #inTip>
          <div class="mt-5">
            <vue-highcharts
                :key="windowWidth"
                v-if="tip.chartOptions"
                type="chart"
                :options="tip.chartOptions"/>
          </div>
        </template>
      </InfoTip>
    </div>

  </div>

  <div class="graph-section mt-60">

    <div class="d-flex f-j-between f-align-center f-gap-16">

      <div class="common-btn-group-purple-transparent mode-picker">
        <div :class="{'not-active': mode !== 'sources'}" @click="mode = 'sources'"><span>Sources</span></div>
        <div :class="{'not-active': mode !== 'countries'}" @click="mode = 'countries'"><span>Countries</span></div>
      </div>

      <div class="d-flex f-align-center f-gap-16">

        <CustomSelectNew
            :options="[
              {label: 'Day'},
              {label: 'Week'},
              {label: 'Month'},
          ]"
            @changed="selectedChartStep = $event.label"
            :pre-selected="{label: 'Day'}"
            :styles="{label: {width: '140px'}}"
        ></CustomSelectNew>

        <CustomSelectNew
            :options="parameters.filter((parameter) => parameter.label !== selectedParameters[1]?.label)"
            :pre-selected="selectedParameters[0] || parameters[0]"
            :styles="{label: {width: '257px'}}"
            @changed="selectedParameters[0] = $event"
            :key="selectedParameters[0].label"
        ></CustomSelectNew>

        <div class="common-btn-group-purple-transparent showing-mode-picker">
          <div v-for="(modeData, mode) in showingModes" :key="mode" :class="{'not-active': selectedShowingModes[0] !== mode}" @click="selectedShowingModes[0] = mode">
            <component :is="modeData.icon" :fill="selectedShowingModes[0] === mode ? '#5D66FE' : '#A3B0C5'"/>
          </div>
        </div>

        <div style="font-size: 16px; font-weight: 600; color: #505660">VS</div>

        <CustomSelectNew
            :options="parameters.filter((parameter) => parameter.label !== selectedParameters[0].label)"
            :styles="{label: {width: '257px'}}"
            empty-label="Select metric"
            @changed="selectedParameters[1] = $event"
            :is-cancelable="true"
            :pre-selected="selectedParameters[1] || null"
            :key="selectedParameters[1]?.label"
        ></CustomSelectNew>

        <div class="common-btn-group-purple-transparent showing-mode-picker">
          <div v-for="(modeData, mode) in showingModes" :key="mode" :class="{'not-active': selectedShowingModes[1] !== mode}" @click="selectedShowingModes[1] = mode">
            <component :is="modeData.icon" :fill="selectedShowingModes[1] === mode ? '#5D66FE' : '#A3B0C5'"/>
          </div>
        </div>

      </div>

    </div>

    <div class="chart-container mt-24">
      <vue-highcharts
          :key="chartKey"
          type="chart"
          :options="chartOptions"
          :redrawOnUpdate="true"
          :oneToOneUpdate="false"
          :animateOnUpdate="true"/>

      <div class="mt-20 ml-12 mb-19">
        <div class="d-flex f-j-center f-gap-16 f-wrap">
          <template v-for="group in visibleGroupsStorage">
            <div class="d-flex f-align-center" v-if="itemGroups[group.group]">
              <div class="circle" :style="{'background-color': itemGroups[group.group].color }"></div>
              <span class="color-800 font-w-400 font-s-12">{{ itemGroups[group.group].title }}</span>
            </div>
          </template>
<!--          <div class="d-flex f-align-center" v-if="selectedParameters[1]">-->
<!--            <div class="circle dashed"></div>-->
<!--            <span>{{selectedParameters[1].label}}</span>-->
<!--          </div>-->
        </div>
      </div>
    </div>

  </div>

  <div class="table-section mt-40 mb-33">
    <SimpleInfoTable default-sort-index="impressions"
                     ref="tableRef"
                     default-sort-direction="desc"
                     :configurable-columns="true"
                     :columns="tableColumns"
                     table-id="store-analytics-table"
                     :items="tableItems"
                     :multiselect="true"
                     checked-prop="isChecked"
                     :max-checked-count="10"
                     @all-items-checked="handleCheckAll"
                     @item-checked="handleCheckRow"
    >
      <template v-slot:headers="slotProps">
        <custom-tooltip width="250px" v-if="slotProps.tooltip">
          <template v-slot:slotTrigger>
            {{ slotProps.header }}
          </template>
          <template v-slot:slotContent>
            {{ slotProps.tooltip }}
          </template>
        </custom-tooltip>
        <template v-else>
          {{ slotProps.header }}
        </template>
      </template>
      <template v-slot:tableOptions>
        <div class="mr-12 d-flex f-gap-6 f-align-center">
          <input type="checkbox" class="common-checkbox" id="show-changes" v-model="isShowTableChanges"/>
          <label class="color-800 cursor-pointer" for="show-changes">
            Show Changes
          </label>
        </div>
      </template>
      <template v-slot:items-country="slotProps">
        <div class="d-flex f-align-center">
          <dynamic-image classes="country-flag"
                         :width="14"
                         :height="10"
                         :country-code="slotProps.item.country.code"/>
          <div>
            {{ slotProps.item.country.name }}
          </div>
        </div>
      </template>
      <template v-slot:items-source="slotProps">
        <div>
          {{ slotProps.item.source }}
        </div>
      </template>
      <template v-slot:items-impressions="slotProps">
        <Dynamic :show-dynamic="isShowTableChanges" v-bind="slotProps.item.impressions" :formatter="shortUnits" :dynamic-formatter="percentFormatter" dynamic-font-size="14px"/>
      </template>
      <template v-slot:items-impressions_cvr="slotProps">
        <Dynamic :show-dynamic="isShowTableChanges" v-bind="slotProps.item.impressions_cvr" :formatter="shortUnits" :dynamic-formatter="percentFormatter" dynamic-font-size="14px"/>
      </template>
      <template v-slot:items-views="slotProps">
        <Dynamic :show-dynamic="isShowTableChanges" v-bind="slotProps.item.views" :formatter="shortUnits" :dynamic-formatter="percentFormatter" dynamic-font-size="14px"/>
      </template>
      <template v-slot:items-views_cvr="slotProps">
        <Dynamic :show-dynamic="isShowTableChanges" v-bind="slotProps.item.views_cvr" :formatter="shortUnits" :dynamic-formatter="percentFormatter" dynamic-font-size="14px"/>
      </template>
      <template v-slot:items-installs="slotProps">
        <Dynamic :show-dynamic="isShowTableChanges" v-bind="slotProps.item.installs" :formatter="shortUnits" :dynamic-formatter="percentFormatter" dynamic-font-size="14px"/>
      </template>
      <template v-slot:items-installs_cvr="slotProps">
        <Dynamic :show-dynamic="isShowTableChanges" v-bind="slotProps.item.installs_cvr" :formatter="shortUnits" :dynamic-formatter="percentFormatter" dynamic-font-size="14px"/>
      </template>
      <template v-slot:items-sales="slotProps">
        <Dynamic :show-dynamic="isShowTableChanges" v-bind="slotProps.item.sales" :formatter="shortUnits" :dynamic-formatter="percentFormatter" dynamic-font-size="14px"/>
      </template>
      <template v-slot:items-revenue="slotProps">
        <Dynamic :show-dynamic="isShowTableChanges" v-bind="slotProps.item.revenue" :formatter="(v) => '$' + shortUnits(v)" :dynamic-formatter="percentFormatter" dynamic-font-size="14px"/>
      </template>
    </SimpleInfoTable>
  </div>

  <div class="call-to-action" v-if="!hasConnection">
    <div class="action-container">
      <div class="text">Connect to App Store Console account to see more</div>
      <router-link :to="'/account/integration-settings/app-store/share-email/' + props.app.id">
        <div class="common-btn-purple">Connect Now</div>
      </router-link>
    </div>
  </div>

</div>
</template>

<style lang="scss">
.app-analytics-tooltip {
  font-size: 14px;
  line-height: 25px;
  box-shadow: 0px 4px 8px 0px #A0BEDD4D;
  background-color: #fff;
  border-radius: 4px;

  .circle {
    width: 17px;
    height: 4px;
    border-radius: 2px;
  }

  table {
    border-spacing: 0;

    tr {
      td {
        margin: 0;
        border-bottom: 1px solid #E1E8F2;
        border-right: 1px solid #E1E8F2;
        text-align: right;
        padding: 4px 8px;

        &:first-child {
          text-align: left;
        }
      }

      td:last-child {
        border-right: none;
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.app-analytics {
  position: relative;

  .metrics-section {
    .tip {
      flex: 1;
    }

    .dynamic_container {
      font-size: initial;
      line-height: initial;
    }
  }

  .graph-section {
    .mode-picker {
      > div {
        width: 89px;
      }
    }

    .showing-mode-picker {
      > div {
        padding: 0 8px;
      }
    }

    .common-btn-group-purple-transparent {
      :deep(path) {
        transition: fill 0.3s;
      }

      > *:hover {
        :deep(path) {
          fill: white;
        }
      }
    }

    .chart-container {
      padding: 8px;
      padding-top: 19px;
      border-radius: 8px;
      background: var(--Neutral-0, #FFF);
      box-shadow: 0px 6px 12px 0px rgba(160, 190, 221, 0.24);

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 8px;

        &.dashed {
          width: 12px;
          height: 12px;
          border: 2px dashed #5D66FE;
        }
      }
    }
  }

  .table-section {
    :deep(.table-options) {
      display: flex;
      align-items: center;
    }

    .dynamic_container {
      flex-direction: column-reverse;
      align-items: flex-end;

      :deep(.dynamic_val) {
        margin: 0;
        flex-direction: row-reverse;
      }
    }
  }

  .call-to-action {
    position: absolute;
    top: 0;
    left: -24px;
    right: -24px;
    height: 100%;
    background: linear-gradient(180deg, rgba(248, 251, 254, 0.00) -63.57%, rgba(248, 251, 254, 0.85) 28.41%);
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;

    .action-container {
      border-radius: 8px;
      background: var(--Neutral-200, #F4F8FE);
      padding: 24px;
      box-shadow: 0px 2px 6px 0px rgba(160, 190, 221, 0.40);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 24px;

      .text {
        color: var(--Neutral-900, #242830);
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
      }
    }
  }

}
</style>