import {httpRequest} from "@/api";

export default {
    timeSeries: function (
        appId,
        startDate,
        endDate,
        frequency,
        measures,
        dimension
    ) {
        const promises = [];

        measures.forEach(measure => {
            const params = {
                startTime: startDate,
                endTime: endDate,
                frequency: frequency,
            }

            let queryString = new URLSearchParams(params).toString();
            queryString += `&measures[]=${measure}`
            if (dimension) {
                queryString += `&group[dimension]=${dimension}&group[metric]=${measure}`
            }

            promises.push(
                httpRequest('GET',process.env.VUE_APP_API_URL + `api/applications/${appId}/analytics/time-series?${queryString}`, params)
            )
        })

        return Promise.all(promises);
    },

    userAppConnections: function (appId) {
        return httpRequest('GET',process.env.VUE_APP_API_URL + `api/applications/user-app-connection?app_id=${appId}`)
    },
};