<template>
  <div class="multiple-select" v-click-outside="() => isOpen = false">
    <div class="label" @click="isOpen = !isOpen" :style="styles.label ?? {}">
      <slot name="label" :selected="selected">
        <span v-if="selected" class="selected"><svg-icon v-if="iconField" :icon="selected[iconField]"/>{{ getOptionLabel(selected) }}</span>
        <span v-else-if="emptyLabel" class="selected" style="color: #838E9E">{{ emptyLabel }}</span>
      </slot>
      <svg-icon v-if="selected && isCancelable" icon="close" @click.stop="select(null)"/>
      <svg-icon v-else icon="angle-down-solid" :class="{rotate: isOpen}"/>
    </div>
    <div class="options" v-if="isOpen">
      <div v-for="option in options">
        <template v-if="option.options && option.label">
          <div class="group-label">{{ option.label }}</div>
          <div v-for="sub_option in option.options" class="options-line">
            <label :for="'ms_' + sub_option[valueField]" @click="select(option)">{{ getOptionLabel(sub_option) }}</label>
          </div>
        </template>
        <template v-else>
          <div class="options-line">
            <label :for="'ms_' + option[valueField]" @click="select(option)"><svg-icon v-if="iconField" :icon="option[iconField]"/>{{ getOptionLabel(option) }}</label>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Label from "@/components/UI/Label/index.vue";
import SvgIcon from "../../UI/SvgIcon/index.vue";

export default {
  name: "CustomSelectNew",
  components: {SvgIcon, Label},
  emits: ['changed'],
  props: {
    styles: {
      type: Object,
      default: () => ({})
    },

    options: {
      type: Array
    },
    preSelected: {
      type: Object,
      default: null
    },
    valueField: {
      type: String,
      default: 'value'
    },
    labelField: {
      type: [String, Function],
      default: 'label'
    },
    iconField: {
      type: String,
      default: null
    },
    emptyLabel: {
      type: String,
      default: 'Select'
    },
    isCancelable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selected: null,
      isOpen: false,
      skipNextUpdate: false
    }
  },

  mounted() {
    if (this.preSelected) {
      this.selected = this.preSelected;
    }
  },

  methods: {
    getOptionLabel(option) {
      if (typeof this.labelField === 'function') {
        return this.labelField(option);
      }
      return option[this.labelField];
    },
    select(value) {
      this.isOpen = false;
      this.selected = value;
      this.$emit('changed',  this.selected);
    },
  },
}
</script>

<style scoped lang="scss">
.multiple-select {
  width: max-content;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--neutral-800);
  user-select: none;
  position: relative;

  svg {
    margin: 0 0 0 10px;
  }

  svg.rotate {
    transform: rotate(180deg);
  }

  .options-line, .selected {
    display: flex;
    svg {
      margin: 0;
      margin-right: 8px;
      font-size: 22px;
    }
  }

  .group-label {
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  label {
    cursor: pointer;
    width: 100%;
    display: flex;
  }

  .options {
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    top: calc(100% + 5px);
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px #A0BEDD4D;
    padding: 12px;
    z-index: 10;

    .options-line {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 6px 8px;
      margin: 0 -8px;

      &:hover {
        background: var(--Neutral-400, #E1E8F2);
      }
    }
  }

  .label {
    padding: 6px 16px;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0px 4px 8px 0px #A0BEDD4D;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>