const makeMockForMeasure = (measure, dateFrom, dateTo) => {
    const mock = [];
    const date = new Date(dateFrom);
    while (date <= dateTo) {
        mock.push({
            date: date.toISOString(),
            [measure]: Math.floor(Math.random() * 100)
        });
        date.setDate(date.getDate() + 1);
    }
    return [
        {
            group: {
                "key": "Search",
                "title": "App Store Search"
            },
            data: mock,
            totals: {
                value: mock.reduce((acc, item) => acc + item[measure], 0),
                type: "COUNT",
                key: measure
            }
        }
    ];
}

export default makeMockForMeasure